import React from 'react'
import './MainSponsor.css'
import MainSponsorLogo from '../../assets/logos/Supporter-Logo.png'

function MainSponsor() {

    return (

        <section id="supporters" className="section-with-bg wow fadeInUp">

            <div className="container">

                <div className="section-header">

                    <h2>Kart Sponsorumuz</h2>
                </div>

                <div className="row d-flex justify-content-center">

                    <div>
                        <div class="supporter-logo">
                            <a href="https://www.instagram.com/kafkahveumitkoy/">
                                <img src={MainSponsorLogo} class="img-fluid" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainSponsor