import React, { useRef, useState } from 'react'

function IntroCard(props) {

  const [light, setLight] = useState("");
  const [angle, setAngle] = useState(0);
  const [cardStyle, SetCardStyle] = useState({ transform: '0' })

  const divRef = useRef();

  const handleMouseEnter = () => {

    const bounds = divRef.current.getBoundingClientRect();

    const handleMouseMove = (e) => {

      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const leftX = mouseX - bounds.x;
      const topY = mouseY - bounds.y;

      const center = {

        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };

      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

      const angle = Math.log(distance) * 2;

      const light = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #ffffff55,
          #0000000f
        )
      `;

      setLight(light);
      setAngle(angle)
      SetCardStyle({
        transform: `scale3d(1.07, 1.07, 1.07) rotate3d(${center.y / 100}, ${-center.x / 100}, 0, ${angle}deg)`
      })
    };

    divRef.current.addEventListener("mousemove", handleMouseMove);

    return () => {
      divRef.current.removeEventListener("mousemove", handleMouseMove);
    };
  };


  const handleMouseLeave = () => {

    setAngle(0);
    setLight("");
    SetCardStyle({
      transform: 'scale3d(1,1,1) rotate3d(0,0,0,0)'
    })
  };

  const handleTouchStart = () => {

    const bounds = divRef.current.getBoundingClientRect();
  
    const handleTouchMove = (e) => {
  
      e.preventDefault();


      const touch = e.touches[0];
  
      const touchX = touch.clientX;
      const touchY = touch.clientY;
  
      const leftX = touchX - bounds.x;
      const topY = touchY - bounds.y;
  
      const center = {
  
        x: leftX - bounds.width / 2,
        y: topY - bounds.height / 2,
      };
  
      const distance = Math.sqrt(center.x ** 2 + center.y ** 2);
  
      const angle = Math.log(distance) * 2;
  
      const light = `
        radial-gradient(
          circle at
          ${center.x * 2 + bounds.width / 2}px
          ${center.y * 2 + bounds.height / 2}px,
          #ffffff55,
          #0000000f
        )
      `;
  
      setLight(light);
      setAngle(angle)
      SetCardStyle({
        transform: `scale3d(1.07, 1.07, 1.07) rotate3d(${center.y / 100}, ${-center.x / 100}, 0, ${angle}deg)`
      })
    };
  
    divRef.current.addEventListener("touchmove", handleTouchMove);
  
    return () => {
      divRef.current.removeEventListener("touchmove", handleTouchMove);
    };
  };
  
  
  const handleTouchEnd = () => {
  
    setAngle(0);
    setLight("");
    SetCardStyle({
      transform: 'scale3d(1,1,1) rotate3d(0,0,0,0)'
    })
  };
  

  return (

    <div
      className="card"
      style={cardStyle}
      ref={divRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}

    >

      <div className="glow"
        style={{
          background: light,
        }}
      >

        <div className="userinfo" id="userinfo">

          {props.name.replace(/[uiocsg_]/g, function (match) {

            var replacements = {
              'u': 'Ü',
              'i': 'İ',
              'o': 'Ö',
              'c': 'Ç',
              's': 'Ş',
              'g': 'Ğ',
              '_': ' '
            };

            return replacements[match];
          })
          }
        </div>
      </div>
    </div>
  )
}

export default IntroCard