import React, { useState } from 'react'
import './Sponsors.css'
import SponsorCard from './SponsorCard'


function Sponsors(props) {

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [activeFilter, setActiveFilter] = useState(3)

    const filters = [
        {
            name: "Hepsi",
            returnOnly: 'none'
        },
        {
            name: "Bahçeli",
            returnOnly: 'Bahçelievler'
        },
        {
            name: "Tunalı",
            returnOnly: 'Tunalı'
        },
        {
            name: "Ümitköy",
            returnOnly: 'ÜMİTKÖY & ÇAYYOLU'
        },
        {
            name: "Şubeli",
            returnOnly: 'Şubeli'
        },
    ]

    const companies = props.companies

    companies.sort(function (a, b) {

        return b.discount - a.discount;
    })

    companies.sort(function (a, b) {

        const priorities = {

            "Şubeli": 3,
            "Bahçelievler": 2,
            "Tunalı": 1,
            "ÜMİTKÖY & ÇAYYOLU": 0,
        }

        return (priorities[b.location] - priorities[a.location])
    })


    return (

        <section id="schedule">

            <div className='filter-container'>

                <div className={dropdownOpen ? 'filter-dropdown dropdown-opened' : 'filter-dropdown dropdown-closed'}>

                    <span
                        className={dropdownOpen ? 'filter-btn selected' : 'filter-btn'}
                        onClick={() => { setDropdownOpen(!dropdownOpen) }}
                    >

                        {filters[activeFilter].name == 'Hepsi' ? 'Filtrele' : filters[activeFilter].name}
                    </span>

                    {dropdownOpen && filters.map((filter, i) => {
                        return (
                            <span
                                className={activeFilter == i ? 'dropdown-btn selected-filter' : 'dropdown-btn'}
                                onClick={() => { setActiveFilter(i); setDropdownOpen(false) }}
                            >

                                {filter.name}
                            </span>
                        )
                    })
                    }

                </div>
            </div>


            <div
                className="tab-content justify-content-center"
                style={{ paddingRight: '1em', paddingeft: '1em' }}
                onClick={() => setDropdownOpen(false)}
            >

                <div className=" tab-pane fade show active" id="day-1" >

                    {companies.map((comp, key) => {

                        return (

                            (filters[activeFilter].name == 'Hepsi' ||
                                comp.location ==
                                filters[activeFilter].returnOnly) &&
                            <SponsorCard key={key} name={comp.title} ammount={comp.discount} description={comp.detail} mapLocation={comp['google-map-url']} />
                        )
                    })}
                </div>
            </div>
        </section >
    )
}

export default Sponsors